import React from "react";
import './Software.css';
import '../AboutPage/About.css';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';


function Software() {

  const projects = [
    //{name: 'Liar\'s Dice Live', link: 'forthcoming...', description: 'Playing Liar\'s Dice with friends has never been easier!', privacy: '/LiarsDiceLivePrivacyPolicy'},
    {name: 'Cocktail Plus', link: 'https://apps.apple.com/us/app/cocktail-plus/id6444221525', description: 'Discover new recipes, try them out, and favorite them for later!', privacy: '/CocktailPlusPrivacyPolicy'},
    {name: 'USA Chess Rating Estimator', link: 'https://apps.apple.com/us/app/usa-chess-rating-estimator/id6449649079', description: 'Estimate your post tournament chess rating!', privacy: '/USAChessPrivacyPolicy'},
    {name: 'Easy Recipe Book', link: 'https://apps.apple.com/us/app/easy-recipe-book/id6477545555', description: 'Go paperless and store all of your recipes or recipe links in one place with Easy Recipe Book.', privacy: '/RecipeBookPrivacyPolicy'},
    {name: 'IP Data Finder', link: 'https://apps.apple.com/us/app/ip-data-finder/id6478026468', description: 'Search an IP address and quickly see the corresponding location, service provider, and location information.', privacy: '/IPFinderPrivacyPolicy'}
    //{name: 'JournalShare', link: 'forthcoming...', description: 'Ever wondered what was going on in other peoples minds?  Now you can read anonymous journal entries in JournalShare!', privacy: '/JournalSharePrivacyPolicy'}
    //{name: 'BracketDub', link: 'bracketdub.com', description: 'Generate probabilistic outcomes based on various rating systems to help make the best March Bracket possible!', privacy: '/BracketDubPrivacyPolicy'}
  ];

  const results = [];

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  const aboutBackgroundStyle = isMobile ? 'about-text-background-mobile' : 'about-text-background';

  results.push(
    <div className="top-space">
      
    </div>
  );

  projects.forEach((project, index) => {

    if (project.link === "forthcoming...") {
      results.push(
        <div key={index} className={aboutBackgroundStyle}>
          <h2 className="about-heading">{project.name}</h2>
          <u1 className="privacy-links">
                     
            <Link className="about-subheading" to={project.privacy}>
               <li>Privacy Policy</li>
            </Link>

            <div className="about-subheading">
              |
            </div>
          
            <div className="about-subheading">
              {project.link}
            </div>
          </u1>
          <p className="about-text">
            {project.description}
          </p>
        </div>
      
      );
    } else {
      results.push(
        <div key={index} className={aboutBackgroundStyle}>
          <h2 className="about-heading">{project.name}</h2>
          <u1 className="privacy-links">
                     
                     <Link className="about-subheading" to={project.privacy}>
                        <li>Privacy Policy</li>
                     </Link>
         
                     <div className="about-subheading">
                       |
                     </div>
                   
                     <a href={project.link} target="_blank" rel="noreferrer" className="about-subheading">
                        App Store
                     </a>
          </u1>
          <p className="about-text">
            {project.description}
          </p>
          <Link className="privacy-link" to={project.privacy}>
              <li></li>
          </Link>
        </div>
      
      );
    }

    results.push(
      <div className="project-padding">

      </div>
    );
  });



  return (
    <div className="software">
      {results}
    </div>
  );
}

export default Software;