import React, { useRef } from 'react';
import './Home.css';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Stars } from '@react-three/drei';

function Box() {
    const boxRef = useRef();

    useFrame(() => {
        boxRef.current.rotation.x += 0.005
        boxRef.current.rotation.y += 0.01;
    });

    return (
        <mesh ref={boxRef} rotation-x={Math.PI * 0.25} rotation-y={Math.PI * 0.25}>
            <torusKnotBufferGeometry attach="geometry" />
            <meshLambertMaterial attach="material" color="gold" flatShading="true"/>
        </mesh>
    );
}


function Home() {
  return (

    <div className="Home">
        <Canvas>
            <OrbitControls />
            <Stars />
            <ambientLight intensity={0.5} />
            <spotLight position={[10,15,10]} angle={0.3} />
            <Box />
        </Canvas>
    </div>



  );
}

export default Home;
