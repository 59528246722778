import React from "react";
import '../AboutPage/About.css';
import { useMediaQuery } from 'react-responsive';


function CocktailPlusPrivacyPolicy() {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  const aboutBackgroundStyle = isMobile ? 'about-text-background-mobile' : 'about-text-background';
  
  const results = [];

  results.push(
    <div className="top-space">

    </div>
  );

  results.push(
      <div className={aboutBackgroundStyle}>
      <h2 className="about-heading">Privacy Policy</h2>
      <div className="about-subheading-for-non-software">Cocktail Plus</div>
        <p className="about-text">
            The application Cocktail Plus does not collect or store user data at any point in
            downloading or using the app.
        </p> 
      </div>
  );

  return (
    <div className="about">
      {results}
    </div>

  );
}

export default CocktailPlusPrivacyPolicy;
