import React from "react";
import pp1 from './PrivacyPolicyPics/pp1.png';
import pp2 from './PrivacyPolicyPics/pp2.png';
import pp3 from './PrivacyPolicyPics/pp3.png';
import pp4 from './PrivacyPolicyPics/pp4.png';
import pp5 from './PrivacyPolicyPics/pp5.png';
import pp6 from './PrivacyPolicyPics/pp6.png';
import pp7 from './PrivacyPolicyPics/pp7.png';
import pp8 from './PrivacyPolicyPics/pp8.png';
import pp9 from './PrivacyPolicyPics/pp9.png';
import pp10 from './PrivacyPolicyPics/pp10.png';
import pp11 from './PrivacyPolicyPics/pp11.png';
import pp12 from './PrivacyPolicyPics/pp12.png';


function JournalSharePrivacyPolicy() {

    const privacyPolicy = [];

    privacyPolicy.push(
        <img src={pp1} alt="pp1" height={400} />
    );

    privacyPolicy.push(
        <img src={pp2} alt="pp2" height={400}/>
    );

    privacyPolicy.push(
        <img src={pp3} alt="pp3" height={400}/>
    );

    privacyPolicy.push(
        <img src={pp4} alt="pp4" height={400}/>
    );

    privacyPolicy.push(
        <img src={pp5} alt="pp5" height={400}/>
    );

    privacyPolicy.push(
        <img src={pp6} alt="pp6" height={400}/>
    );

    privacyPolicy.push(
        <img src={pp7} alt="pp7" height={400}/>
    );

    privacyPolicy.push(
        <img src={pp8} alt="pp8" height={400}/>
    );

    privacyPolicy.push(
        <img src={pp9} alt="pp9" height={400}/>
    );

    privacyPolicy.push(
        <img src={pp10} alt="pp10" height={400}/>
    );

    privacyPolicy.push(
        <img src={pp11} alt="pp11" height={400}/>
    );

    privacyPolicy.push(
        <img src={pp12} alt="pp12" height={400}/>
    );

    return (
        <div>
            {privacyPolicy}
        </div>
    );
}

export default JournalSharePrivacyPolicy;