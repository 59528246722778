import React from 'react';
import './App.css';
import Nav from '../NavBar/Nav';
import About from '../AboutPage/About';
import Software from '../SoftwarePage/Software';
import Contact from '../ContactPage/Contact'
import Home from './Home';
import CocktailPlusPrivacyPolicy from '../PrivacyPolicies/CocktailPlusPrivacyPolicy';
import {Routes, Route} from 'react-router-dom';
import LiarsDiceLivePrivacyPolicy from '../PrivacyPolicies/LiarsDiceLivePrivacyPolicy';
import BracketDubPrivacyPolicy from '../PrivacyPolicies/BracketDubPrivacyPolicy';
import USAChessPrivacyPolicy from '../PrivacyPolicies/USAChessPrivacyPolicy';
import JournalSharePrivacyPolicy from '../PrivacyPolicies/JournalSharePrivacyPolicy';
import RecipeBookPrivacyPolicy from '../PrivacyPolicies/RecipeBookPrivacyPolicy';
import IPFinderPrivacyPolicy from '../PrivacyPolicies/IPFinderPrivacyPolicy';


function App() {
  
  return (
      <div className="App">
        <Nav />
        <Routes>
          <Route path="/" element={ <Home/> }/>
          <Route path="about" element={ <About/> }/>
          <Route path="software" element={ <Software/> }/>
          <Route path="contact" element={ <Contact/> }/>
          <Route path="cocktailplusprivacypolicy" element={ <CocktailPlusPrivacyPolicy/> }/>
          <Route path="liarsdiceliveprivacypolicy" element={ <LiarsDiceLivePrivacyPolicy/> }/>
          <Route path="bracketdubprivacypolicy" element={ <BracketDubPrivacyPolicy/> }/>
          <Route path="usachessprivacypolicy" element={ <USAChessPrivacyPolicy/> }/>
          <Route path="recipebookprivacypolicy" element={ <RecipeBookPrivacyPolicy/> }/>
          <Route path="journalshareprivacypolicy" element={ <JournalSharePrivacyPolicy/> }/>
          <Route path="ipfinderprivacypolicy" element={ <IPFinderPrivacyPolicy/> }/>
        </Routes>
      </div>

  );
}

export default App;
