import React from "react";
import './About.css';
import { useMediaQuery } from 'react-responsive';


function About() {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  const aboutBackgroundStyle = isMobile ? 'about-text-background-mobile' : 'about-text-background';

  const results = [];

  results.push(
    <div className="top-space">

    </div>
  );

  results.push(
      <div className={aboutBackgroundStyle}>
      <h2 className="about-heading">Apps with Style</h2>
        <p className="about-text">
            ATK Applications was founded in July 2022 with the singular mission to design, build, 
            and deliver premium consumer facing applications.
        </p> 
      </div>
  );

  return (
    <div className="about">
      {results}
    </div>

  );
}

export default About;
